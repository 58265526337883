<template>
<div class="signin-up-bg">


  <br>
  <br>
  <br>

  <img alt="Vue logo" src="../../assets/logo_meals.png"> +
  <img alt="Vue logo" src="../../assets/logo-logomark.png">

  <br>
  <br>
    <div class="position-relative">
      <div class="login-form">
        <h1 class="text-uppercase- text-purple text-center mb-5">Login</h1>
          <div class="form-group">
            <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email" v-model="email">
          </div>
          <div class="form-group mb-4">
            <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Enter Password" v-model="password">
          </div>
          <div class="form-group clearfix">
            <button type="submit" class="btn login_btn float-right" @click="login_button_click">LOGIN</button>
          </div>
      </div>

    </div>
</div>

</template>

<script>

import { mapActions } from 'vuex'
import { variables } from '../../constants/systemConstants'
// import helpermixins from '../../mixins/helperMixins'

export default {
  components: {

  },
  data () {
    return {

      email: '',
      password: '',
        // email: 'admin.alvi@booking.com',
      // password: 'NewAlvi3404',
      notification_tag: 'Login TubeC'
    }
  },

  mounted () {
  },

  created () {
  },
  methods: {
    ...mapActions(['changeUser']),
    makeToast(variant = null, data, title) {
      // @ts-ignore
      this.$bvToast.toast(data, {
        title: title,
        variant: variant,
        solid: true
      });
    },

    login_button_click () {
      let app = this

      if (this.email === '') {
        app.makeToast(variables.warning, 'Please add your email', app.notification_tag)
        return
      }

      // if (!this.validEmail(this.email)) {
      //   app.makeToast(variables.warning, 'Invalid email', app.notification_tag)
      //   return
      // }

      if (this.password === '') {
        app.makeToast(variables.warning, 'Please enter the password', app.notification_tag)
        return
      }

      this.$auth.login({
        params: {
          email: app.email,
          password: app.password
        },
        success: function () {
          app.$auth.fetch().then(
            function (res) {
              app.changeUser(res.data)
            }
          )
        },
        error: function (resp) {
          app.makeToast(variables.danger, resp.response.data.error, 'Login error')
        },
        rememberMe: true,
        redirect: '/'
      })
    }

  },
  watch: {
  }
}
</script>

<style scoped>


.position-relative {
  padding-left: 1%;
  padding-right: 1%;
  width: 100%;
}

.login_btn{
  background-color: #FFCA28;
}
@media screen and (max-width: 900px) and (min-width: 600px), (min-width: 1100px) {
  .position-relative {

    padding-left: 25%;
    padding-right: 25%;
    width: 100%;

  }
}



</style>
